import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { hasRole, isAuthenticated } from './utils';
const CustomLayout = React.lazy(() => import('./containers/Layout'));
const Appointment = React.lazy(() => import('./containers/Appointment'));
const MyProfile = React.lazy(() => import('./containers/MyProfile'));
const Approval = React.lazy(() => import('./containers/Auth/Approval'));
const Prescription = React.lazy(() =>
  import('./containers/Appointment/Prescription')
);
const PrescriptionReview = React.lazy(() =>
  import('./containers/Appointment/PrescriptionPreview')
);
const AgoraVideoCall = React.lazy(() =>
  import('./containers/Appointment/VideoCall/VideoCall')
);
const Availabilty = React.lazy(() =>
  import('./containers/Calendar/Availabilty')
);
const VoiceCall = React.lazy(() =>
  import('./containers/Appointment/VoiceCall')
);
const PatientTracker = React.lazy(() => import('./containers/PatientTracker'));
const DoctorList = React.lazy(() => import('./containers/DoctorListTable'));
const DoctorPriority = React.lazy(() => import('./containers/DoctorPriority'));
const PatientList = React.lazy(() => import('./containers/PatientList'));
const PatientListTable = React.lazy(() =>
  import('./containers/PatientListTable')
);
const PatientProfile = React.lazy(() =>
  import('./containers/PatientListTable/PatientProfile')
);
const PatientPlanTable = React.lazy(() =>
  import('./containers/PatientPlanTable')
);
const PatientPlanEdit = React.lazy(() =>
  import('./containers/PatientPlanTable/PlanEditForm')
);
const DoctorProfile = React.lazy(() => import('./containers/DoctorProfile/'));
const UnassignSlots = React.lazy(() =>
  import('./containers/UnassignedSlots/UnassignSlots')
);
const LeadNotification = React.lazy(() =>
  import('./containers/LeadNotification/LeadNotification')
);
const LabReports = React.lazy(() =>
  import('./containers/labReports/LabReports')
);
const ClaimRecords = React.lazy(() =>
  import('./containers/ClaimStatusRecords')
);
const ClaimReports = React.lazy(() => import('./containers/ClaimsReports'));
const PinCode = React.lazy(() => import('./containers/Pincode'));
const Vendor = React.lazy(() => import('./containers/Vendor'));
const ClaimList = React.lazy(() => import('./containers/ClaimList'));
const CareTeamAvailibility = React.lazy(() =>
  import('./components/CareTeam/CareTeamAvailibility')
);
const Login = React.lazy(() => import('./containers/Auth/Login'));
const Home = React.lazy(() => import('./containers/Home'));
const Calendar = React.lazy(() => import('./containers/Calendar'));
const AddAppointment = React.lazy(() =>
  import('./containers/Appointment/AddAppointment/AddAppointment')
);
const Signup = React.lazy(() => import('./containers/Auth/Signup'));
const ExotelDashboard = React.lazy(() =>
  import('./containers/ExotelDashboard')
);
const ClaimDetails = React.lazy(() =>
  import('./containers/MutualAid/claimDetails/index')
);

const PatientPolicyDetails = React.lazy(() =>
  import('./containers/PolicyDetails/PatientPolicyDetails')
);
const ProductDetails = React.lazy(() =>
  import('./containers/PolicyDetails/ProductDetails')
);

const AmbulanceOnCall = React.lazy(() =>
  import('./containers/AmbulanceOnCall')
);

const Reports = React.lazy(() => import('./containers/Reports/index'));
const PartnerWiseAppointmentReport = React.lazy(() =>
  import('./containers/Reports/PartnerWiseAppointmentReport/index')
);
const AgentWiseIncomingCallingReport = React.lazy(() =>
  import('./containers/Reports/AgentWiseIncomingCallingReport/index')
);
const PartnerWiseCallingReport = React.lazy(() =>
  import('./containers/Reports/PartnerWiseCallingReport/index')
);
const StatusWiseDailyAppointmentReport = React.lazy(() =>
  import('./containers/Reports/StatusWiseDailyAppointmentReport/index')
);
const AppointmentTypeWiseDailyAppointmentReport = React.lazy(() =>
  import('./containers/Reports/AppointmentTypeWiseDailyAppointmentReport/index')
);
const DoctorWiseAppointmentReport = React.lazy(() =>
  import('./containers/Reports/DoctorWiseAppointmentReport/index')
);

// make route private
const PrivateRoute = ({ component: Component, role, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated(role) ? <Component /> : <Redirect to="/login" />
      }
    />
  );
};

const BaseRouter = () => (
  <Switch>
    {/* =============================================== */}
    {/* UnProtected routes */}
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/approval" component={Approval} />
    <PrivateRoute
      path="/video-call/:doctor/:appointment/:patient/:roomId"
      component={AgoraVideoCall}
      role={['Admin', 'Doctor']}
    />
    <PrivateRoute
      path="/voice-call"
      component={VoiceCall}
      role={['Admin', 'Doctor']}
    />

    {/* =============================================== */}
    {/* Protected routes */}
    <CustomLayout>
      <PrivateRoute
        exact
        path="/"
        component={Home}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        exact
        path="/calendar"
        component={Calendar}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/appointment/:value"
        exact
        component={Appointment}
        role={['Admin', 'Doctor', 'Doctor_priority_update']}
      />
      <PrivateRoute
        path="/pharma-appointment/:value/:type"
        exact
        component={Appointment}
        role={['pharmacist']}
      />
      <PrivateRoute
        path="/add-appointment"
        component={AddAppointment}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/preview-prescription-form/patient/:id/appointment-id/:appointment"
        component={PrescriptionReview}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/my-profile"
        component={MyProfile}
        role={[
          'Admin',
          'Doctor',
          'Doctor_priority_update',
          'pincode_view',
          'care_plan_details',
          'Team_Roaster_View',
          'Claim_Statistics',
          'cal_logs_view',
          'ambulance_on_call',
          'pharmacist'
        ]}
      />
      <PrivateRoute
        exact
        path="/unassigned-slots"
        component={UnassignSlots}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        exact
        path="/lead-notification"
        component={LeadNotification}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        exact
        path="/lab-test"
        component={LabReports}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/patient/edit/:patient_id"
        component={PatientProfile}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/patient/plans/:patient_id"
        component={PatientPlanTable}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/patient/edit-plan/:plan_id"
        component={PatientPlanEdit}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/appointments/fill-prescription/patient/:id/appointment-id/:appointment"
        component={Prescription}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/add-availability"
        component={Availabilty}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/patient"
        exact
        component={PatientTracker}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/patient-list"
        component={PatientListTable}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/patient/:patient_id"
        exact
        component={PatientList}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/doctors"
        exact
        component={DoctorList}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/claimRecords"
        exact
        component={ClaimRecords}
        role={['Admin', 'Doctor', 'Claim_Statistics']}
      />
      <PrivateRoute
        path="/claimReports"
        exact
        component={ClaimReports}
        role={['Admin', 'Doctor', 'Claim_Statistics']}
      />
      <PrivateRoute
        path="/doctors-priority"
        exact
        component={DoctorPriority}
        role={['Admin', 'Doctor', 'Doctor_priority_update']}
      />
      <PrivateRoute
        path="/vendor"
        exact
        component={Vendor}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/pincode"
        component={PinCode}
        role={['Admin', 'Doctor', 'pincode_view']}
      />
      <PrivateRoute
        path="/claimList"
        component={ClaimList}
        role={['Admin', 'Doctor', 'Claim_Statistics']}
      />
      <PrivateRoute
        path="/call-logs"
        component={ExotelDashboard}
        role={['Admin', 'Doctor', 'cal_logs_view']}
      />
      <PrivateRoute
        path="/ambulance-on-call"
        component={AmbulanceOnCall}
        role={['Admin', 'Doctor', 'ambulance_on_call']}
      />
      <PrivateRoute
        path="/doctors/:doctor_id"
        exact
        component={DoctorProfile}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/care-availability"
        exact
        component={CareTeamAvailibility}
        role={['Admin', 'Doctor', 'Team_Roaster_View']}
      />
      <PrivateRoute
        path="/claimDetails/:claimId/:subClaimId"
        exact
        component={ClaimDetails}
        role={['Admin', 'Doctor']}
      />
      <PrivateRoute
        path="/patient-policy-details"
        exact
        component={PatientPolicyDetails}
        role={['Admin', 'Doctor', 'care_plan_details']}
      />
      <PrivateRoute
        path="/product-sub-policy-details"
        exact
        component={ProductDetails}
        role={['Admin', 'Doctor', 'care_plan_details']}
      />
      <PrivateRoute
        path="/reports"
        exact
        component={Reports}
        role={['Admin']}
      />
      <PrivateRoute
        path="/reports/partner-wise-appointment-report"
        exact
        component={PartnerWiseAppointmentReport}
        role={['Admin']}
      />
      <PrivateRoute
        path="/reports/agent-wise-incoming-calling-report"
        exact
        component={AgentWiseIncomingCallingReport}
        role={['Admin']}
      />
      <PrivateRoute
        path="/reports/partner-wise-calling-report"
        exact
        component={PartnerWiseCallingReport}
        role={['Admin']}
      />
      <PrivateRoute
        path="/reports/status-wise-daily-appointment-report"
        exact
        component={StatusWiseDailyAppointmentReport}
        role={['Admin']}
      />
      <PrivateRoute
        path="/reports/appointment-type-wise-daily-appointment-report"
        exact
        component={AppointmentTypeWiseDailyAppointmentReport}
        role={['Admin']}
      />
      <PrivateRoute
        path="/reports/doctor-wise-appointment-report"
        exact
        component={DoctorWiseAppointmentReport}
        role={['Admin']}
      />
    </CustomLayout>
  </Switch>
);

export default BaseRouter;
