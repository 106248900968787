import React from 'react';
import ReactDOM from 'react-dom';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { Provider } from 'react-redux';
import store from 'src/redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './rsuite.css';
import ErrorBoundary from './ErrorBoundary/index';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
