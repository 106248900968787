import axios from 'axios';
import {
  loginURL,
  registerURL,
  doctorSpecialityUrl,
  adminLogoutURL,
  postFcmTokenURL
} from '../constants';
import { toast } from 'src/components/Generic/Message';
import { headers, isAuthenticated } from 'src/utils';
import { GetApi, PostApi } from 'src/services/middleware';

// login api
export const authLogin = async (mobile, password, fcm_token, navigation) => {
  try {
    const res = await PostApi(`${loginURL}`, {
      mobile,
      password,
      fcm_token
    });
    if (res.status === 200) {
      localStorage.setItem('token', res?.data?.token);
      localStorage.setItem('auth_token', res?.data?.['Auth-Token']);
      localStorage.setItem('name', res?.data?.data?.name);
      localStorage.setItem('mobile', mobile);
      if (res?.data?.data?.user_role) {
        localStorage.setItem(
          'user_roles',
          JSON.stringify(res?.data?.data?.user_role)
        );
      }
      
      let route = '';
      if (isAuthenticated(['Admin', 'Doctor'])) {
        route = '/';
      } else if (isAuthenticated(['pharmacist'])) {
        route = `/pharma-appointment/today/e-clinic`;
      }
      navigation.push(route)
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// doctor register api
export const doctorRegister = async (data, is_last, navigation) => {
  try {
    const res = await PostApi(`${registerURL}`, data, headers());
    if (res.status === 200) {
      // if register page is last page to fill up then navigate to somehere
      if (is_last) {
        navigation.push('/approval');
      }
      toast.success('Profile details saved');
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// fetch doctor specialities
export const doctorSpeciality = async () => {
  try {
    const res = await GetApi(`${doctorSpecialityUrl}`, headers());
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// admin logout
export const adminLogout = async (data) => {
  try {
    const res = await PostApi(`${adminLogoutURL}`, data, headers());
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// user fcm  token post
export const postFcmToken = async (data) => {
  const res = await PostApi(`${postFcmTokenURL}`, data, headers());
  if (res.status === 200) {
    return res;
  }
};
