export const USER_ROLES = {
  Doctor: 'Doctor',
  IO_Reviewer: 'IO_Reviewer',
  IO_Reviewer_Update: 'IO_Reviewer_Update',
  DOC_Reviewer: 'DOC_Reviewer',
  SDOC_Reviewer: 'SDOC_Reviewer',
  FO_Reviewer: 'FO_Reviewer',
  Patient: 'Patient',
  ADMIN_REVIEWER: 'ADMIN_REVIEWER',
  Admin: 'Admin',
  Doctor: 'Doctor',
  Doctor_priority_update: 'Doctor_priority_update',
  Claim_Statistics: 'Claim_Statistics',
  pincode_view: 'pincode_view',
  Team_Roaster_View: 'Team_Roaster_View',
  cal_logs_view: 'cal_logs_view',
  care_plan_details: 'care_plan_details',
  ambulance_on_call: 'ambulance_on_call',
  pharmacist: 'pharmacist'
};

export const appointmentType = [
  'appointment_created',
  'pharmacist_joined_call',
  'doctor_joined_call',
  'aiqa_center_joined_call'
];

export const primaryColor = '#db2228';
export const infoColor = '#3B71CA';
