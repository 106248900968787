import { adminLogout } from 'src/redux/action/auth';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const isAuthenticated = (role) => {
  const token = localStorage.getItem('token');
  const authToken = localStorage.getItem('auth_token');
  let isRole = false;
  for (let i = 0; i <= role?.length; i++) {
    if (hasRole(role[i])) {
      isRole = true;
      break;
    }
  }
  if (token == null || token == ' ' || !authToken) {
    isRole = false;
  }
  return isRole;
};

export const headers = () => {
  const token = localStorage.getItem('token');
  const authToken = localStorage.getItem('auth_token');
  return {
    headers: {
      Authorization: `Token ${token}`,
      'Auth-Token': authToken,
      'content-type': 'application/json'
    }
  };
};

export const hasRole = (role) => {
  let userRoles = localStorage.getItem('user_roles');
  if (userRoles) {
    userRoles = JSON.parse(userRoles);
    const obj = userRoles.find((o) => o.role_name === role);
    if (obj && Object.keys(obj).length > 0) {
      return true;
    }
  }
  return false;
};

export const appendUnit = (vital, reading1, reading2) => {
  switch (vital) {
    case 'Blood Pressure':
      return reading1.toFixed(1) + '/' + reading2.toFixed(1) + ' mmhg';
    case 'Glucoses':
      return `${reading1} mg/dL`;
    case 'Weight':
      return `${reading1} kgs`;
    case 'Temperature':
      return `${reading1} °F`;
    case 'Spo2':
      return `${reading1} %`;
    case 'Pulse':
      return `${reading1} bpn`;
    case 'Height':
      return `${reading1} cms`;
    default:
      return '';
  }
};

export const formattedDate = (a) => {
  const d = new Date(a);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  // const year = String(d.getFullYear());
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return `${day}/${month}`;
};

export const humanize = (str) => {
  if (str == null || str == '') {
    return '';
  }
  let i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

export let client_access_key =
  process.env[
    `REACT_APP_LAB_TEST_CLIENT_ACCESS_KEY_${process.env.REACT_APP_MODE}`
  ];
export let client_access_secret =
  process.env[
    `REACT_APP_LAB_TEST_CLIENT_ACCESS_SECRET_${process.env.REACT_APP_MODE}`
  ];

export let enrollment_client_id =
  process.env[`REACT_APP_ENROLLMENT_CLIENT_ID_${process.env.REACT_APP_MODE}`];

export let enrollment_client_secret =
  process.env[
    `REACT_APP_ENROLLMENT_CLIENT-SECRET_${process.env.REACT_APP_MODE}`
  ];

export const Claimheaders = () => {
  const token = localStorage.getItem('token');
  const authToken = localStorage.getItem('auth_token');
  return {
    headers: {
      'X-RESELLER-Client-Id': client_access_key,
      'X-RESELLER-Client-Secret': client_access_secret,
      Authorization: `Token ${token}`,
      'Auth-Token': authToken
    }
  };
};

export const EnrollmentHeaders = () => {
  const token = localStorage.getItem('token');
  const authToken = localStorage.getItem('auth_token');
  return {
    headers: {
      'X-RESELLER-Client-Id': enrollment_client_id,
      'X-RESELLER-Client-Secret': enrollment_client_secret,
      Authorization: `Token ${token}`,
      'Auth-Token': authToken
    }
  };
};

export const convertTo12HourFormat = (time24hr) => {
  if (time24hr) {
    const [hours, minutes, sec] = time24hr?.split(':');
    let period = 'AM';

    let hour = parseInt(hours, 10);
    if (hour === 0) {
      hour = 12;
    } else if (hour >= 12) {
      hour %= 12;
      period = 'PM';
    }

    return `${hour == '0' ? '00' : hour}:${minutes}:${sec} ${period}`;
  } else return '----';
};
export const formatSeconds = (seconds) => {
  if (seconds) {
    if (seconds >= 60) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''}`;
    }
  } else return '----';
};

export const hideLastFourCharacters = (input) => {
  if (input == null) return;
  let str = String(input);

  if (str?.length <= 4) {
    return 'X'.repeat(str?.length);
  }

  const lastFourChars = str?.slice(-4);
  const maskedString = 'X'.repeat(input?.length - 4) + lastFourChars;

  return maskedString;
};

export const logOut = (history) => {
  const mobileNumber = localStorage.getItem('mobile');
  const fcmToken = getFcmToken();
  const data = {
    mobile: mobileNumber,
    fcm_token: fcmToken
  };
  adminLogout(data).then((res) => {
    if (res?.status === 200) {
      localStorage.clear();
      history.replace('/login');
    }
  });
};

let fcmToken = null;

export const getFcmToken = () => fcmToken;
export const setFcmToken = (value) => {
  fcmToken = value;
};

let loginUserID = null;

export const getLoginUserID = () => loginUserID;
export const setLoginUserID = (value) => {
  loginUserID = value;
};
