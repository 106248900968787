import { w3cwebsocket } from 'websocket';
import { addNativeNotification } from 'src/components/Notification';
import axios from 'axios';
import { headers } from 'src/utils';
import { toast } from 'src/components/Generic/Message';
import { GetApi } from 'src/services/middleware';

export let URL = process.env.REACT_APP_URL;
export let PATIENT_URL = process.env.REACT_APP_PATIENT_URL;
export let SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
export let RESELLER_URL = process.env.REACT_APP_RESELLER_URL;
export let ENROLLMENT_SERVICE_URL =
  process.env.REACT_APP_ENROLLMENT_SERVICE_URL;
export let CLAIM_SERVICE_URL = process.env.REACT_APP_CLAIM_SERVICE_URL;
export let socket = void 0;
export const startSocket = (id, history, getNotificationData) => {
  socket = new w3cwebsocket(`${SOCKET_URL}/notification/${id}/`); // socket Url
  // socket.send();
  if (socket) {
    socket.onopen = () => {
      console.log(':::WebSocket Connected at Client:::');
    };
    socket.onmessage = (message) => {
      try {
        let data = message.data;
        if (JSON.parse(message.data)) {
          data = JSON.parse(data);
        }
        if (data.read === true) {
          getNotificationData && getNotificationData();
          return;
        }
        let onClick = void 0;
        if (data.notification_type === 'prescription') {
          onClick = (event) => {
            window.focus();
            history.push(
              `/appointments/fill-prescription/patient/${data.patient}/appointment-id/${data.appointment}/`
            );
          };
        } else if (data.notification_type === 'appointment') {
          onClick = () => {
            window.focus();
            history.push(`/patient/${data.patient}`);
          };
        }
        addNativeNotification({
          title: `aiqahealth : ${
            data.data && data.data.notification_type
              ? data.data.notification_type
              : data.notification_type
          }`,
          message: data.data && data.data.body ? data.data.body : data.body,
          duration: 10000,
          native: true,
          onClick
        });
      } catch (e) {
        console.log(':::errro is', e);
      }
    };
    socket.onclose = function () {
      console.log(':::::WebSocket Client Closed:::::');
      setSocket(null);
    };
  }
};

export const getSocket = () => {
  return socket;
};
export const setSocket = (status) => {
  socket = status;
};

// auth url
export const loginURL = `${PATIENT_URL}/accounts/api/admin-login/`;
export const registerURL = `${PATIENT_URL}/accounts/api/doctor-register/`;

// profile url
export const adminProfileDetailURL = `${URL}/doctor/api/admin-profile/`; //admin profile basic details
export const doctorProfileDetailURL = `${URL}/doctor/api/admin-doctor-profile/`; //doctor profile basic details
export const bankDetailURL = `${URL}/doctor/api/doctor-detail/`; //doctor profile bank details

export const patientClaimURL = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/claimDetails/${id}`;
export const ByInsuredMemberIdURL = (id) =>
  `${RESELLER_URL}/patient-claim-info/${id}`; //patient profile details

export const patientClaimURLByInsuredMemberUrl = (id) =>
  `${RESELLER_URL}/patient-claim-info/${id}`; //patient profile details

export const claimOverallSummaryURL = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/claimRecords`;

export const claimOverallSummaryDataURL = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/getReviewerClaimRecords`;

export const claimSummaryDataByStatusURL = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/getClaimRecordsByStatus`;

export const getClaimResllerURL = (user_id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/reseller?searchKey=${user_id}`;

export const ClaimReviewDetailURL = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/getClaimReviewDetails/${id}`;

export const patientPlanDetailURL = `${RESELLER_URL}/patient-plan`; //patient plan details
export const patientProfileDetailURL = (patient) =>
  `${URL}/doctor/api/patient-profile/?patient=${patient}`; //patient profile details
export const checkPatientMemberURL = (application_id) =>
  `${RESELLER_URL}/check-member/${application_id}`; //patient profile details
export const patientProductURL = (application_id) =>
  `${RESELLER_URL}/patient-plan/${application_id}`; //patient product details
export const invoiceUrl = (application_id) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/invoice/getInvoice/${application_id}/true`;

export const addMemberURL = `${RESELLER_URL}/add-member`; //Add Member
export const reedemCouponURL = `${RESELLER_URL}/coupon-apply`; //apply Reedem Coupon

export const adminPatientProfileDetailURL = (patient) =>
  `${URL}/doctor/api/admin-patient/?id=${patient}`; //patient profile details
export const getPatientMemberURL = (user_id) =>
  `${URL}/doctor/api/admin-member/?user_id=${user_id}`; //fetch patient member
export const patientDeleteURL = `${URL}/doctor/api/admin-patient/`; //patient profile details
// appointment url
export const appointmentListURL = (date, query, page) =>
  `${URL}/doctor/api/admin-todays-appointments/?date=${date}&search=${query}&page=${page}`;

export const fetchAppointmentURL = (page, filter, from_date, to_date, sort) =>
  `${URL}/doctor/api/admin-todays-appointments/?page=${page}&status=${filter}&from_date=${from_date}&to_date=${to_date}&ordering=${sort}`; //fetch  appointments

export const fetchPharmacistAppointmentURL = (
  page,
  filter,
  from_date,
  to_date,
  pharmacist_id
) =>
  `${URL}/doctor/api/admin-todays-appointments/?page=${page}&status=${filter}&from_date=${from_date}&created_from=reseller&to_date=${to_date}&pharmacist_id=${pharmacist_id}&ordering=-call_join_time%2Cappointment_date%2Cappointment_time`; //fetch pharmacist appointments

export const postEclinicStatusUpdateURL = `${URL}/doctor/api/eclinic-status-update/`;

// cancel appointment url
export const cancelAppointmentURL = `${URL}/doctor/api/cancel-appointment/`;
export const fetchStatusURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/filterWiseLabTestStatusCount/`;
export const appointmentPresciptionURL = `${URL}/doctor/api/admin-send-prescription/`; //send  Appointment Presciption
export const uploadReportURL = `${URL}/doctor/api/admin-patient-report/`; //Upload  Report
export const getMemberURL = `${URL}/doctor/api/admin-member/`; //get Member
export const createAppointmentURL = `${URL}/doctor/api/admin-doctor-appointment/`; //create appointment
export const createSpecialtyAppointmentURL = `${URL}/doctor/api/specialty-appointment/`; //create appointment
export const fetchTodayAppointmentURL = (query) =>
  `${URL}/doctor/api/todays-appointment/?cursor=${query}`; //fetch today appointments

//disease, symptom, vital fetch url
export const fetchDiseaseURL = `${PATIENT_URL}/patient/api/disease-list/`; //fetch disease list
export const fetchSymptomListURL = (disease_id) =>
  `${URL}/doctor/api/symptom-list/?disease_id=${disease_id}`; //fetch symptom list
export const fetchSymptomOptionListURL = (symptom_id) =>
  `${URL}/doctor/api/symptom-option-list/?symptom_id=${symptom_id}`; //fetch symptom option list

// prescription url
export const publishPrescriptionURL = `${URL}/doctor/api/patient-prescription-publish/`; //publish prescription

export const updateSymptomVitalURL = `${URL}/doctor/api/patient-disease-option/`; //update consultation details

export const deletePrescriptionURL = `${URL}/doctor/api/prescription-delete/`; //prescription delete

export const medicalHistoryUpdateURL = `${URL}/patient/api/medical-history/`; //update patient medical history detail
export const medicalHistoryFetchURL = (patient) =>
  `${URL}/patient/api/medical-history/?patient=${patient}`; //fetch patient medical history

export const socialHistoryUpdateURL = `${URL}/patient/api/social-history/`; //update patient social history detail
export const socialHistoryFetchURL = (patient) =>
  `${URL}/patient/api/social-history/?patient=${patient}`; //fetch patient social history

export const familyHistoryUpdateURL = `${URL}/patient/api/family-history/`; //update patient family history detail
export const familyHistoryFetchURL = (patient) =>
  `${URL}/patient/api/family-history/?patient=${patient}`; //fetch patient family history

export const diagnosisUpdateURL = `${URL}/doctor/api/diagnosis/`; //update patient diagnosis detail
export const diagnosisFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/diagnosis/?patient=${patient}&appointment=${appointment}`; //update patient family history

export const medicationCreateURL = `${URL}/doctor/api/add-medicine/`; // patient medication create
export const medicationFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/add-medicine/?patient=${patient}&appointment=${appointment}`; //fetch patient medications

export const dietCreateURL = `${URL}/doctor/api/add-diet/`; //update patient diet create
export const dietFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/add-diet/?patient=${patient}&appointment=${appointment}`; //fetch patient diet

export const exerciseCreateURL = `${URL}/doctor/api/add-exercise/`; // patient exercise create
export const exerciseFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/add-exercise/?patient=${patient}&appointment=${appointment}`; //fetch patient exercise

export const woundCareCreateURL = `${URL}/doctor/api/add-wound-care/`; // patient wound care create
export const woundCareFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/add-wound-care/?patient=${patient}&appointment=${appointment}`; //fetch wound care

export const labTestCreateURL = `${URL}/doctor/api/add-lab-test/`; // patient lab test create
export const labTestFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/add-lab-test/?patient=${patient}&appointment=${appointment}`; //fetch lab tests

export const otherAdviceUpdateURL = `${URL}/doctor/api/detail-advice/`; //update patient advice
export const otherAdviceFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/detail-advice/?patient=${patient}&appointment=${appointment}`; //fetch other advice

// patient search api
export const patientFetchURL = (query) =>
  `${URL}/doctor/api/patient-search/?search=${query}`;

export const doctorAppointmentURL = (doctor_id, page) =>
  `${URL}/doctor/api/admin-doctor-appointments/?doctor_id=${doctor_id}&page=${page}`;
// patient search api
export const doctorPrescriptionURL = (doctor_id, page, startDate, endDate) =>
  `${URL}/doctor/api/doctor-prescription/?doctor_id=${doctor_id}&page=${page}&start_date=${startDate}&end_date=${endDate}`;
// patient search api

export const previousPrescriptionURL = (patient, page) =>
  `${URL}/doctor/api/all-prescription/?patient=${patient}&page=${page}`;

export const downloardPrescriptionURL = (user_id, patient_id) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/prescription/${user_id}/${patient_id}`; // download prescription

export const doctorFetchURL = (query) =>
  `${URL}/doctor/api/all-doctors/?search=${query}`;
// get consultation api
export const fetchPatientConsultationURL = (appointment, patient) =>
  `${URL}/doctor/api/patient-disease-option/?appointment=${appointment}&patient=${patient}`;

// get new consultation api
export const fetchPatientNewConsultationURL = (appointment, patient) =>
  `${URL}/doctor/api/chief-complain/?appointment=${appointment}&patient=${patient}`;

// prescription upload api
export const uploadPrescriptionURL = (appointment, patient) =>
  `${URL}/doctor/api/prescription/?appointment=${appointment}&patient=${patient}`;

// counsellor notes api
export const counsellorNotesURL = (patient, appointment) =>
  `${URL}/doctor/api/counselor-report/?appointment=${appointment}&patient=${patient}`;

// follow up api
export const appointmentFollowUpURL = (patient, appointment) =>
  `${URL}/doctor/api/appointment-followup/?appointment=${appointment}&patient=${patient}`;

// patient report list api
export const patientPreviousReportURL = (patient) =>
  `${URL}/doctor/api/report-list/?patient=${patient}`;

// patient report list api with pagination
export const patientPreviousReportPaginURL = (patient, page, report_type) =>
  `${URL}/doctor/api/get-report-list/?patient=${patient}&page=${page}${
    report_type ? `&report_type=${report_type}` : ''
  }`;

// fetch all symptom list
export const fetchAllSymptomURL = `${URL}/doctor/api/all-symptom-list/`;

// fetch all symptom list
export const patientTrackerURL = (patient, appointment) =>
  `${URL}/doctor/api/new-symptoms/?patient=${patient}&appointment=${appointment}`;

// upload generated prescription report
export const uploadGeneratedPdfURL = () =>
  `${URL}/doctor/api/upload-prescription/`;

// initiate exotel call
// export const exotelCall = (patient) =>
//   `${URL}/doctor/api/make-call/?patient=${patient}`;

// create doctor available time slots
export const createTimeSlots = (doctor_id) =>
  `${URL}/doctor/api/doctor-availability/?doctor=${doctor_id}`;

// create parameter track e.g vital, symptom
export const createParametersTrack = `${URL}/doctor/api/patient-parameters-track/`;

// fetch parameter track vitals an symptom
export const parametersTrackFetchURL = (patient, appointment) =>
  `${URL}/doctor/api/patient-default-disease/?patient_id=${patient}&appointment_id=${appointment}`;

// fetch vital list
export const fetchVitalListURL = `${URL}/doctor/api/get-vitals/`;

// fetch appointment pending
export const fetchPendingAppointmentURL = (date, page) =>
  `${URL}/doctor/api/admin-previous-appointments/?date=${date}&page=${page}`;

// fetch patient filled symptoms
export const filledSymptomsUrl = `${PATIENT_URL}/patient/api/patient-filled-symptoms/`;

// fetch patient filled vitals
export const filledVitalsUrl = `${PATIENT_URL}/patient/api/patient-filled-vitals/`;

// fetch patient filled symptoms by doctor
export const filledSymptomsDoctorUrl = `${PATIENT_URL}/patient/api/patient-filled-symptoms-doctor/`;

// fetch patient filled vitals by doctor
export const filledVitalsDoctorUrl = `${PATIENT_URL}/patient/api/patient-filled-vitals-doctor/`;

export const filledVitalsListDoctorUrl = `${PATIENT_URL}/patient/api/get-vitals/`;
// fetch patient appointments
export const patientAppointmentUrl = `${URL}/doctor/api/patient-appointments/`;

// fetch doctor patient list
export const patientListUrl = `${URL}/doctor/api/patient-search-admin-filterset/`;

export const doctorListUrl = `${URL}/doctor/api/doctors-list/`;
export const doctorListCountUrl = `${URL}/doctor/api/doctor-list-count`; //
export const doctorPriorityListUrl = `${URL}/doctor/api/doctor-rating/`;
// fetch patient plans list
export const patientPlanListUrl = (id) =>
  `${URL}/doctor/api/admin-patient-subscriptions/?id=${id}`;

// fetch patient plan detail
export const patientPlanDetailsUrl = (id) =>
  `${URL}/doctor/api/admin-subscription/?id=${id}`;

// fetch all plan
export const fetchAllPlanListUrl = `${PATIENT_URL}/patient/api/all-plan/`;

// delete diagnosis image
export const deleteDiagnosisImageURL = `${URL}/doctor/api/diagnosis-delete/`;

// fetch patient diagnosis report
export const patientDiagnosisUrl = `${URL}/doctor/api/diagnosis-report/`;

// fetch appointment
export const fetchAppointmentDetail = (id) =>
  `${URL}/doctor/api/appointment-detail/?id=${id}`;

// appointment url
export const calendarAppointmentListURL = (date, query) =>
  `${URL}/doctor/api/admin-callendar/?date=${date}&search=${query}`;

// fetch vitals List
export const vitalListUrl = `${URL}/doctor/api/get-vitals/`;

// fetch patient appointement count
export const patientAppointementCount = (patient) =>
  `${URL}/doctor/api/appointment-count/?patient=${patient}`;

// fetch doctor specilities
export const doctorSpecialityUrl = `${URL}/doctor/api/doctor-specialty/`;

// fetch doctor detail
export const doctorDetailUrl = `${URL}/doctor/api/admin-home/`;

// ==========================
// ADMIN
// ==========================

// fetch previous appointments
export const adminFetchPreviousAppointmentURL = (search) =>
  `${URL}/doctor/api/admin-previous-appointments/?search=${search}`;

// fetch patient adherence day wise
export const fetchPatientAdherence = (patient, start, end) =>
  `${URL}/doctor/api/admin-day-wise-total-adherence/?patient=${patient}&start=${start}&end=${end}`;

// fetch patient adherence (e.g diet, exercise)
export const fetchPatientAdherenceURL = (patient, start, end) =>
  `${URL}/doctor/api/patient-adherence/?patient=${patient}&start=${start}&end=${end}`;

// fetch unassigned time slots
export const fetchUnassignedSlotsURL = () => `${URL}/doctor/api/unavailable/`;

// fetch unassigned time slots
export const fetchAppointmentLeadURL = (page) =>
  `${PATIENT_URL}/patient/api/appointment-leads/?page=${page}`;

/// lab test urls  ///////
export const getLabTestUrl = (filter) =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/${filter}`;
// cancel lab test
export const cancelLabTestUrl = (filter, id) =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/${filter}/cancel_booking/${id}`;

//rescheduleBookingUrl
export const rescheduleBookingUrl = (filter, id) =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/${filter}/rescheduleLabTest/${id}`;

// get active vendor url
export const getActiveVendorUrl = `${CLAIM_SERVICE_URL}/claim-service/labtest/activePartners`;
export const getRescheduleBookingDetailsUrl = `${CLAIM_SERVICE_URL}/claim-service/labtest/rescheduleBookingDetails`;

export const updateBookingDetails = `${CLAIM_SERVICE_URL}/claim-service/labtest/update_booking_details`;
export const updateSampleCollectionDetails = `${CLAIM_SERVICE_URL}/claim-service/labtest/update_sample_collection_details`;
export const uploadReport = (id, user) =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/upload_test_report/${id}/${user}`;
export const getLabTestReportUrl = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/download_lab_report/${id}/download`;

export const sendNotificationUrl = (status, id) =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/sms_notification/${status}/${id}`;

export const getSlotesUrl = (status) =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/${status}/getSlotByZipcode`;
///////////

export const notificaitonListUrl = `${URL}/doctor/api/notification-list/?user_type=care_team`;

export const fetchNotificationList = async () => {
  try {
    const res = await GetApi(`${notificaitonListUrl}`, headers());

    if (res.status == 200) {
      return {
        notifications: res.data,
        notification_count: res.count
      };
    }
  } catch (err) {
    toast.error('something went wrong');
  }
};

// api for fetch disable date
export const getDisabledDates = `${ENROLLMENT_SERVICE_URL}/enrollment-service/NonServiceableDay`;

// download appointment details
export const downloadAppointmentDetailsURL = (startDate, endDate, status) =>
  `${URL}/doctor/api/appointment-csv/?status=${status}&start_date=${startDate}&end_date=${endDate}`;

// care availability
export const getCareAvailabilityURL = (date) =>
  `${URL}/doctor/api/carepanelroaster/?start_date=${date}`;

//Search Care List
export const getCareListURL = (v) =>
  `${URL}/doctor/api/carepanelsearch/?search=${v}`;

//Get timing and Slots Care List
export const getSlotsTimeURL = `${URL}/doctor/api/carepanelroaster-shift/`;

//Adding Availability
export const postCareAvailableURL = `${URL}/doctor/api/carepanelroaster/`;
//api for calling in lab test
//initiate exotel call

export const exotelCall = `${URL}/doctor/api/exotel-multipurpose-call/`;
export const exotelData = (page, query) =>
  `${URL}/doctor/api/exotel-list/?page=${page}${query}`;
export const exotelCallDetail = (call_sid) =>
  `${URL}/doctor/api/exotel-call-detail/?call_sid=${call_sid}`;

export const exotelSaveDisposition = `${URL}/doctor/api/exotel-list/`;

export const validatePinCodeURL = (code) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/open/validatePinCode/${code}`;

// pincode details
export const addPinCodeUrl = `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/pincode/addPincode`;
export const pinCodeDetailsUrl = `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/pincode/getAllPinCodeDetails`;
export const vendorListUrl = `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/pincode/getVendorList`;
export const editPinCodeUrl = `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/pincode/updatePincodeDetails`;
export const deletePinCodeUrl = `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/pincode/deletePincodeDetails`;

// get state by pincode url
export const getStateUrl = (pinCode) => `${RESELLER_URL}/check-pin/${pinCode}`;

//HRA
export const hraIdURL = () =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/hra_reports/`;

export const hraReportURL = (data, key) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/hra_report_fetch/${data}/${key} `;

export const hospitalsURL = `${RESELLER_URL}/hospitals`; //get all hospitals

export const productBenefitURL = `${RESELLER_URL}/product`; //get product benefits

// fetch city url
export const getCityUrl = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/city/?stateId=${id}`;

// PM jay Id url
export const postPMJayIdURL = `${URL}/doctor/api/add-pmjay-id/`; //patient plan details

// remove doctor available time slots
export const removeTimeSlotsURL = `${URL}/doctor/api/doctor-remove-time-slot/`;

// get doc time slot
export const docTimeSlotURL = (id, date) =>
  `${URL}/doctor/api/appointment-time-api/?doctor_id=${id}&date=${date}`;
// CLAIM RECORDS API

export const getClaimRecordsResellerURL = (key) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/reseller/getResellerName/${key}`;
export const getClaimRecordsProductURL = (key) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/product/productName/${key}`;

export const getClaimReportDataForPaginationURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/claimReport/getClaimReportDataForPagination`;

export const getClaimReportDataForDownloadURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/claimReport/getClaimReportDetails`;

// getAllRoles
export const getReviewerListURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/claimReport/getAllRoles/`;
//get plan details
export const planDetailsUrl = (mobile) =>
  `${ENROLLMENT_SERVICE_URL}/enrollment-service/api/insuredMember/customer_plan/${mobile}`;

// Ambulance on Call
export const getAllAOCDataURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/aoc_fetch`;

export const addAOCDataURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/aoc_add`;
export const updateAOCDataURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/updateAocRequest`;
export const sendToVendorURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/aoc_send_to_vendor`;
export const bookAmbulanceURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/send_aoc_request_to_vendor_for_booking`;
export const fetchAllInsuredMembersDataURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/getinsuredMemberNameOrMobileNumber`;
export const pickAmbulanceURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/send_aoc_request_to_vendor_after_pickup`;
export const dropOfAmbulanceURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/send_aoc_request_to_vendor_after_drop_off`;
export const onCancelledAmbulanceURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/send_aoc_request_to_vendor_for_cancelled`;

export const getAOCDataByIdURL = (id) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/aoc/getAocRequestById/${id}`;

export const getPincodeURL = (key) =>
  `${CLAIM_SERVICE_URL}/claim-service/api/location?pincode=${key}`;

// get patner lab report URL

export const getPatnerLabReportURL = () =>
  `${CLAIM_SERVICE_URL}/claim-service/labtest/getLabTestDetailsByMobile`;

// admin logout
export const adminLogoutURL = `${PATIENT_URL}/accounts/api/care-logout/`;

// fcm token
export const postFcmTokenURL = `${URL}/doctor/api/regenerate-fcm-token/`;
