import axios from 'axios';
import { toast } from 'src/components/Generic/Message';

export const GetApi = async (URL, header) => {
  try {
    const response = await axios.get(URL, header);
    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 'SUCCESS'
    ) {
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      toast.error('Session expired. Redirecting you to the login page.');
      setTimeout(() => {
        localStorage.clear();
        window.location.assign('/login');
      }, 1000);
    } else {
      toast.error('Something went wrong.');
      return error;
    }
  }
};

export const PostApi = async (URL, data, header = {}) => {
  try {
    const response = await axios.post(URL, data, header);

    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 'SUCCESS'
    ) {
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      toast.error('Session expired. Redirecting you to the login page.');
      setTimeout(() => {
        localStorage.clear();
        window.location.assign('/login');
      }, 1000);
    } else {
      toast.error('Something went wrong.');
      return error;
    }
  }
};

export const DeleteApi = async (URL, data = {}) => {
  try {
    const response = await axios.delete(URL, data);

    if (
      response?.status === 200 ||
      response?.status === 201 ||
      response?.status === 'SUCCESS'
    ) {
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      toast.error('Session expired. Redirecting you to the login page.');
      setTimeout(() => {
        localStorage.clear();
        window.location.assign('/login');
      }, 1000);
    } else {
      toast.error('Something went wrong.');
      return error;
    }
  }
};
