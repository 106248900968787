import React, { Component, Suspense, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import BaseRouter from './routes';
import ReactToast, { toast } from './components/Generic/Message';
import './style.scss';
import './style.css';

import { Backdrop, CircularProgress } from '@material-ui/core';
import {
  getToken,
  onMessage,
  getMessaging,
  isSupported
} from '@firebase/messaging';
import { messaging } from './firebase';
import { setFcmToken } from './utils';
import CustomizedSnackbars from './components/CustomizedSnackbars';
import { postAppointmentStatus } from './redux/action/appointmentStatus';
import { useDispatch } from 'react-redux';
import SweetAlerts from './components/common/sweetAlert/SweetAlerts';
import { appointmentType, infoColor, primaryColor } from './constants';
import { postFcmToken } from './redux/action/auth';

const App = () => {
  const [option, setOption] = useState({
    open: false
  });
  const [notificationData, setNotificationData] = useState([]);
  const dispatch = useDispatch();

  const postFCMTokenHandler = (fcmToken) => {
    const userId = localStorage.getItem('Login_User_ID');
    const token = localStorage.getItem('fcmToken');
    const authToken = localStorage.getItem('auth_token');
    const data = {
      user_id: userId,
      fcm_token: fcmToken
    };
    if (fcmToken && !token && authToken)
      postFcmToken(data).then((res) => {
        if (fcmToken && res) localStorage.setItem('fcmToken', fcmToken);
      });
  };

  const getRequestPermission = async () => {
    if (
      Notification.permission == 'default' ||
      Notification.permission == 'denied'
    ) {
      localStorage.removeItem('fcmToken');
    }

    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await getToken(await messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY
        });
        postFCMTokenHandler(token);
        if (token) {
          setFcmToken(token);
        }
      } else if (Notification.permission === 'denied') {
        console.log('Permission is denied');
      }
    } catch (error) {
      console.error('Error getting FCM token', error);
    }
  };

  const onMessageListener = async () => {
    const token = localStorage.getItem('auth_token');
    getRequestPermission();
    const preData = notificationData;
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      onMessage(getMessaging(), (payload) => {
        if (token) {
          if (appointmentType.includes(payload?.data?.event_type)) {
            dispatch(postAppointmentStatus(true, 'FETCH_APPOINTMENT_STATUS'));
          }
          setOption({
            open: true
          });
          setNotificationData([
            ...preData,
            {
              ...payload
            }
          ]);
        }
      });
    } else {
      console.log('your browser not supported web push notification');
    }
  };

  useEffect(() => {
    onMessageListener();
  }, []);

  const onCancelHandler = (item) => {
    let newData = [];
    const oldData = notificationData;
    oldData.map((elem) => {
      if (item.messageId != elem.messageId) {
        newData.push(elem);
      }
    });
    setNotificationData(newData);
  };

  const onViewNotification = (item) => {
    window.location.replace(item.data?.app_route);
  };

  const buttons = (item) => {
    return (
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          width: '100%',
          gap: '20px'
        }}
      >
        <button
          style={{
            borderRadius: 5,
            color: 'white',
            backgroundColor: primaryColor,
            padding: 10,
            border: 'none',
            cursor: 'pointer'
          }}
          onClick={() => onCancelHandler(item)}
        >
          Close
        </button>
        {item.data?.app_route && (
          <button
            style={{
              borderRadius: 5,
              color: 'white',
              backgroundColor: infoColor,
              padding: 10,
              border: 'none',
              cursor: 'pointer'
            }}
            onClick={() => onViewNotification(item)}
          >
            View
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      {notificationData.length > 0 &&
        notificationData.map((item, index) => {
          return (
            <SweetAlerts
              key={index}
              open={true}
              title={item.notification?.title}
              subTitle={item.notification?.body}
              onCancel={() => onCancelHandler(item)}
              onConfirm={() => {}}
              info={true}
              hideOverlay={index > 0}
              style={{
                top: index > 0 ? `${index * 5}px` : '',
                left: index > 0 ? `${index * 5}px` : '',
                boxShadow:
                  'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
              }}
              customButtons={buttons(item)}
            />
          );
        })}
      <CustomizedSnackbars option={option} setOption={setOption} />
      <Suspense
        fallback={
          <Backdrop
            style={{ color: '#fff', backgroundColor: '#fff' }}
            open={true}
          >
            <CircularProgress color="error" size={50} />
          </Backdrop>
        }
      >
        <Router>
          <ReactToast />
          <BaseRouter />
        </Router>
      </Suspense>
    </>
  );
};

export default App;
