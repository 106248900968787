import React from 'react';
import toast, { Toaster } from 'react-hot-toast';

const ReactToast = () => {
  return <Toaster />;
};
const getToastStyle = (type) => {
  let color = type === 'error' ? 'red' : 'green';
  return {
    style: {
      border: '1px solid ' + color,
      padding: '16px',
      color: color
    },
    iconTheme: {
      primary: color,
      secondary: '#FFFAEE'
    }
  };
};

export default ReactToast;
const toastFunction = {
  error: (message) => {
    toast.error(message, {
      id: message,
      ...getToastStyle('error')
    });
  },
  success: (message) => {
    toast.success(message, { id: message, ...getToastStyle('success') });
  }
};
export { toastFunction as toast };
