import authReducer from './auth';
import appointmentStatus from './appointmentStatus';
import commonReducer from './commonReducer';
import { combineReducers } from 'redux';
import notificationReducer from './notificationReducer';
export default combineReducers({
  auth: authReducer,
  appointment_status: appointmentStatus,
  common: commonReducer,
  notification: notificationReducer
});
