import React from 'react';
import Ringtone from '../assets/sounds/notification-sound.mp3';

export default function CustomizedSnackbars({ option }) {
  React.useEffect(() => {
    let audio;
    if (option?.open) {
      audio = new Audio(Ringtone);
      try {
        audio.play();
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    }
    return () => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    };
  }, [option]);

  return <></>;
}
