import * as actionTypes from './actionTypes';

export const fetchSuccess = (data) => {
  return {
    type: actionTypes.FETCH_APPOINTMENT_STATUS,
    data: data
  };
};

export const postAppointmentStatus = (value) => {
  return (dispatch) => {
    dispatch(fetchSuccess(value));
  };
};
