import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const SweetAlerts = ({
  onConfirm,
  onCancel,
  type = 'success',
  title,
  subTitle,
  open,
  key,
  info = false,
  hideOverlay = false,
  style = {},
  customButtons = false
}) => {
  return (
    <SweetAlert
      key={key}
      warning={info ? false : true}
      info={info}
      showCancel
      confirmBtnText="Yes"
      confirmBtnBsStyle={type}
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      show={open}
      closeOnClickOutside={false}
      hideOverlay={hideOverlay}
      style={style}
      customButtons={customButtons}
    >
      {subTitle}
    </SweetAlert>
  );
};

export default SweetAlerts;
